import T from "@redwit-commons/utils/typecheck";
import {
  GoonoResponse,
  mkResponseSchema,
  GoonoArrayResponse,
  mkArrayResultsSchema,
  GoonoPendingTasks,
} from "./common";
import { GraphsDataObject, GraphsDataObjectSchema } from "../object/graphs";
import {
  ParsedTaskObject,
  ParsedTaskObjectSchema,
} from "@redwit-commons/object/task";

export type OGetGraphsData = GoonoResponse<GraphsDataObject>;
export const OGetGraphsDataSchema = mkResponseSchema(GraphsDataObjectSchema);
export const validateOGetGraphsData =
  T.mkValidator<OGetGraphsData>(OGetGraphsDataSchema);

export type OAdminClientModify = GoonoResponse<{ user_id: string }> &
  GoonoPendingTasks<{ admin_task: string }>;
export const OAdminClientModifySchema = mkResponseSchema(
  T.object().addField("user_id", T.string()),
  T.object().addField("admin_task", T.string())
);
export const validateOAdminClientModify = T.mkValidator<OAdminClientModify>(
  OAdminClientModifySchema
);

export type OAdminClientModifyPending = GoonoArrayResponse<ParsedTaskObject>;
export const OAdminClientModifyPendingSchema = mkArrayResultsSchema(
  ParsedTaskObjectSchema
);
export const validateOAdminClientModifyPending =
  T.mkValidator<OAdminClientModifyPending>(OAdminClientModifyPendingSchema);
