import React from "react";
import loadable from "@loadable/component";
import { DiaLogStateStatus } from "src/redux/store/reducers/dialog";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";

const Dialog = loadable(() => import("../../components/public/modals/Dialog"));

export default function GoonoDialogManager() {
  const dialog = useSelector((state: RootState) => state.dialog);
  if (dialog.state.status !== DiaLogStateStatus.OPENED) return <></>;
  return <Dialog {...dialog.state} />;
}
