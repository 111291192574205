import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  WorkspaceAction,
  WorkspaceActionKind,
  WorkspaceState,
  workspaceStateMachine,
  WorkspaceStateMachineType,
  WorkspaceStateStatus,
} from "../store/reducers/workspace";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.workspace,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type WorkspaceContainerProps = PropsFromRedux & {
  stateMachine: WorkspaceStateMachineType;
};

export const handleWorkspaceAction = (
  prevState: WorkspaceState,
  action: WorkspaceAction
): WorkspaceState => {
  switch (action.kind) {
    case WorkspaceActionKind.TRY_UPDATE_WORKSPACE_INVITATION: {
      return {
        ...prevState,
        workspaceInvitation: action.workspaceInvitation,
      };
    }

    case WorkspaceActionKind.TRY_UPDATE_CURRENT_WORKSPACE_SERVICE: {
      return {
        ...prevState,
        currentMenu: action.service,
      };
    }

    case WorkspaceActionKind.TRY_UPDATE_CURRENT_WORKSPACE: {
      const { last_acessed_workspace_logs } = prevState;

      /** 새 로그랑 겹치는 항목 제거한 logs */
      const filteredAcessedLogs = last_acessed_workspace_logs.filter(
        (log) => log.userId !== action.userId
      );

      return {
        workspaceInvitation: prevState.workspaceInvitation,
        status: WorkspaceStateStatus.SUCCESS,
        currentWorkspace: action.targetWorkspace,
        currentMenu: prevState.currentMenu,
        last_acessed_workspace_logs: [
          ...filteredAcessedLogs,
          { userId: action.userId, workspaceId: action.targetWorkspace.id },
        ],
      };
    }

    case WorkspaceActionKind.TRY_INIT_WORKSPACE: {
      return {
        workspaceInvitation: prevState.workspaceInvitation,
        status: WorkspaceStateStatus.INIT,
        currentMenu: prevState.currentMenu,
        last_acessed_workspace_logs: prevState.last_acessed_workspace_logs,
      };
    }

    default:
      return prevState;
  }
};

class WorkspaceContainer extends ReduxStateComponent3<WorkspaceContainerProps> {
  static defaultProps = {
    stateMachine: workspaceStateMachine,
  };
  constructor(props: WorkspaceContainerProps) {
    super(props);
  }

  protected onAction = handleWorkspaceAction;
}

export default connector(WorkspaceContainer);
