import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { TextValidateResult } from "@utils/functions/input-validator";
import { AuthNoteObject, NoteObject } from "@goono-commons/api/object/note";
import { ProjectDTO } from "@goono-commons/v3/project";
import { ProjectAuthType } from "@goono-commons/api/object/user_project_map";
import { DocumentBox } from "@goono-commons/dataRoom/documentBox";
import { DocumentShareLink } from "@goono-commons/dataRoom/DocumentShareLink";
import React from "react";
import { PDFBuildOptions } from "@goono-commons/api/object/taskv2/pdf";
import { FailedNoteInfo } from "@goono-commons/api/object/taskv2/pdf";
import { FolderObject } from "@goono-commons/api/object/folder";
import { Workspace } from "@goono-commons/v3/workspace";
import { WorkspaceInvitationObject } from "@goono-commons/api/object/workspace";
import { StartFreeWorkspacePlanParams } from "src/handler/workspace/useHandleStartFreeWorkspacePlan";

export enum ModalStateStatus {
  INIT = "Modal::INIT",
}

export enum ModalActionKind {
  TRY_OPEN = "ModalAction::TRY_OPEN",
  TRY_CLOSE = "ModalAction::TRY_CLOSE",
  TRY_CLOSE_ALL = "ModalAction::TRY_CLOSE_ALL",
}

export enum ModalType {
  MOVE_FILE = "ModalType::MOVE_FILE",
  MEMBER_MANAGE = "ModalType::MEMBER_MANAGE",
  DELETE = "ModalType::DELETE",
  TEXT_INPUT = "ModalType::TEXT_INPUT",
  USER_SIGN = "ModalType::USER_SIGN",
  EDIT_TAG = "ModalType::EDIT_TAG",
  VIEW_TAG = "ModalType::VIEW_TAG",
  GITHUB_LINKAGE_LIST = "ModalType::GITHUB_LINKAGE_LIST",
  GITHUB_LINK = "ModalType::GITHUB_LINK",
  PROJECT_INFO = "ModalType::PROJECT_INFO",
  UPDATE_PROJECT_ADMIN = "ModalType::UPDATE_PROJECT_ADMIN",
  TRANSFER_DOCUMENT_BOX_OWNER = "ModalType::TRANSFER_DOCUMENT_BOX_OWNER",
  RECONFIRM = "ModalType::RECONFIRM",
  QUICK_UPLOAD_FILE = "ModalType::QUICK_UPLOAD_FILE",
  DOWNLOAD_PDF = "ModalType::DOWNLOAD_PDF",
  DOWNLOAD_FAIL_FILE = "ModalType::DOWNLOAD_FAIL_FILE",
  PROJECT_PREVIEW = "ModalType::PROJECT_PREVIEW",
  PRIVACY = "ModalType::PRIVACY",
  VIEW_DOWNLOAD_HISTORY = "ModalType::VIEW_DOWNLOAD_HISTORY",
  LINKAGE_GUIDE = "ModalType::LINKAGE_GUIDE",
  BLOCK_INFO = "ModalType::BLOCK_INFO",
  PASSWORD_MODIFY = "ModalType::PASSWORD_MODIFY",
  RESEARCHER_LIST = "ModalType::RESEARCHER_LIST",
  HELP_CENTER = "ModalType::HELP_CENTER",
  UPDATE_ONBOARDING = "ModalType::UPDATE_ONBOARDING",
  DOCUMENT_BOX_MEMBER_MANAGE = "ModalType::DOCUMENT_BOX_MEMBER_MANAGE",
  CREATE_SHARE_LINK = "ModalType::CREATE_SHARE_LINK",
  NDA_TEMPLATE = "ModalType::NDA_TEMPLATE",
  CREATE_SHARE_LINK_COMPLETE = "ModalType::CREATE_SHARE_LINK_COMPLETE",
  MOVE_DOCUMENT = "ModalType::MOVE_DOCUMENT",
  EDIT_NDA_TEMPLATE = "ModalType::EDIT_NDA_TEMPLATE",
  CREATE_NDA_TEMPLATE = "ModalType::CREATE_NDA_TEMPLATE",
  FREE_PLAN_FORM = "ModalType::FREE_PLAN_FORM",
  DATA_ROOM_PRICE = "ModalType::DATA_ROOM_PRICE",
  SELECT_DOCUMENT = "ModalType::SELECT_DOCUMENT",
  DATA_ROOM_GUIDE = "ModalTppe::DATA_ROOM_GUIDE",
  NDA_SIGN_LIST = "ModalType::NDA_SIGN_LIST",
  INVITE_NEW_WORKSPACE = "ModalType::INVITE_NEW_WORKSPACE",
}

export type ModalError = never;

export type CreateNDATemplateModalProps = {
  documentBoxId: string;
};

export type MemberManageModalProps = {
  readonly targetProject: ProjectDTO;
};

export type DeleteModalProps = {
  readonly title: string;
  readonly bodyTitle: string;
  readonly bodyMsg?: string;
  readonly bodyMsgHighlight?: string[];
  readonly confirmMsg: string;
  readonly targetFolder?: { name: string; icon: React.ReactChild };
  readonly onDelete: (() => void) | (() => Promise<void>);
};

export type TextInputModalProps = {
  readonly confirmMsg?: string;
  readonly title?: string;
  readonly subTitle?: string;
  readonly subTitleIcon?: React.ReactNode;
  readonly bodyMsg?: string;
  readonly placeholder?: string;
  readonly helperText?: string;
  readonly maxLength?: number;
  readonly defaultValue?: string;
  readonly errorValidator?: (text: string) => TextValidateResult;
  readonly onSubmit:
    | ((text: string) => void)
    | ((text: string) => Promise<void>);
};

export type UserSignModalProps = {
  readonly disabledCancelButton: boolean;
  readonly onSubmit: ((url: string) => void) | ((url: string) => Promise<void>);
};

export type EditTagModalProps = {
  readonly targetNote: NoteObject;
  readonly onSubmit: (newTags: string[]) => Promise<void>;
};

export type ViewTagModalProps = {
  readonly targetNote: NoteObject;
  readonly projectId: string;
};

export type GithubLinkageListModalProps = {
  project_id: string;
  folder_id?: string;
  userAuth: ProjectAuthType;
};

export type GithubLinkModalProps = {
  readonly project_id: string;
  readonly folder_id?: string;
};

export type MoveFileModalProps = {
  /** true일 경우 바로 폴더 선택으로 넘어감 */
  readonly skipProjectSelect?: boolean;
  readonly from: {
    projectId: string;
    projectName: string;
    folderId: string | undefined;
    folderName: string | undefined;
  };
  readonly moveFiles: (to: {
    projectId: string;
    projectName: string;
    folderId: string | undefined;
    folderName: string | undefined;
  }) => void;
};

export type HelpModalProps = {};

export type UpdateOnboardingModalprops = {};

export type FreePlanFormModalProps = {
  confirmMsg: string;
  onSubmit: (freePlanInfo: StartFreeWorkspacePlanParams) => void;
};

export type DataRoomPriceModalProps = {};

export type DataRoomGuideModalProps = {};

export type DownloadFailFileModalProps = {
  readonly projectName: string;
  readonly failedList: FailedNoteInfo[];
};

export type ResearcherListModalProps = {
  readonly targetProject: ProjectDTO;
};

export type ProjectInfoModalProps = {
  readonly targetProject: ProjectDTO;
};

export type CreateDocumentShareLinkModalProps = {
  readonly id: string;
  readonly fileName: string;
  readonly documentBoxId: string;
  readonly documentBoxFolderId?: string;
};

export type UpdateProjectAdminModalProps = {
  readonly targetProject: ProjectDTO;
};

export type TransferDocumentBoxOwnerModalProps = {
  readonly documentBox: DocumentBox;
};

export type ReconfirmModalProps = {
  readonly title: string;
  readonly bodyTitle?: string;
  readonly bodyMsg: string;
  readonly confirmMsg: string;
  readonly reconfirmInputOption?: {
    answer: string;
  };
  readonly onConfirm: (() => void) | (() => Promise<void>);
};

export type QuickUploadFileModalProps = {
  readonly researchNote: ProjectDTO;
  readonly researchFolder: FolderObject | undefined;
  readonly files: File[];
};

export type DownloadPDFModalProps = {
  readonly projectId: string;
  readonly projectName: string;
  readonly isAdminDashboard?: boolean;
  readonly onConfirm: (pdfBuildOption: PDFBuildOptions) => void | Promise<void>;
};

export type ProjectPreviewModalProps = {
  readonly projectName: string;
};

export type PrivacyModalProps = {};

export type ViewDownloadHistoryModalProps = {};

export type LinkageGuideModalProps = {};

export type BlockInfoModalProps = {
  readonly mainBlockNote: AuthNoteObject;
};
export type PasswordModifyModalProps = {
  readonly hasPasswordChangeReminder: boolean;
};

export type DocumentBoxMemberManageModalProps = {
  documentBox: DocumentBox;
  readOnly?: boolean;
};

export type NDATemplateSelectModalProps = {
  readonly documentBoxId: string;
  readonly onConfirm: (params: { ndaId: string; ndaName: string }) => void;
};

export type CreateDocumentShareLinkCompleteModalProps = {
  readonly shareLinkInfo: DocumentShareLink;
  readonly documentId: string;
  readonly fileName: string;
  readonly documentBoxId: string;
};

export type MoveDocumentModalProps = {
  readonly from: {
    documentBoxId: string;
    folderId: string | null;
  };
  readonly moveDocument: (to: { folderId: string | null }) => void;
};

export type EditNDATemplateModalProps = {
  readonly documentBoxId: string;
  readonly ndaTemplateId: string;
  readonly NDATemplateName: string;
  readonly NDATemplateDesc?: string;
};

export type NdaSignListModalProps = {};

export type InviteNewWorkspaceModalProps = {
  freshWorkspaceList: Workspace[];
  freshInvitationList: WorkspaceInvitationObject[];
};

type GenericModalInfo<T = ModalType, U = any> = {
  readonly type: T;
} & U;

export type ModalInfo =
  | GenericModalInfo<ModalType.CREATE_NDA_TEMPLATE, CreateNDATemplateModalProps>
  | GenericModalInfo<ModalType.MEMBER_MANAGE, MemberManageModalProps>
  | GenericModalInfo<ModalType.DELETE, DeleteModalProps>
  | GenericModalInfo<ModalType.TEXT_INPUT, TextInputModalProps>
  | GenericModalInfo<ModalType.USER_SIGN, UserSignModalProps>
  | GenericModalInfo<ModalType.EDIT_TAG, EditTagModalProps>
  | GenericModalInfo<ModalType.VIEW_TAG, ViewTagModalProps>
  | GenericModalInfo<ModalType.GITHUB_LINKAGE_LIST, GithubLinkageListModalProps>
  | GenericModalInfo<ModalType.GITHUB_LINK, GithubLinkModalProps>
  | GenericModalInfo<ModalType.MOVE_FILE, MoveFileModalProps>
  | GenericModalInfo<ModalType.PROJECT_INFO, ProjectInfoModalProps>
  | GenericModalInfo<
      ModalType.UPDATE_PROJECT_ADMIN,
      UpdateProjectAdminModalProps
    >
  | GenericModalInfo<
      ModalType.TRANSFER_DOCUMENT_BOX_OWNER,
      TransferDocumentBoxOwnerModalProps
    >
  | GenericModalInfo<ModalType.RECONFIRM, ReconfirmModalProps>
  | GenericModalInfo<ModalType.QUICK_UPLOAD_FILE, QuickUploadFileModalProps>
  | GenericModalInfo<ModalType.DOWNLOAD_PDF, DownloadPDFModalProps>
  | GenericModalInfo<ModalType.PROJECT_PREVIEW, ProjectPreviewModalProps>
  | GenericModalInfo<ModalType.PRIVACY, PrivacyModalProps>
  | GenericModalInfo<ModalType.LINKAGE_GUIDE, LinkageGuideModalProps>
  | GenericModalInfo<ModalType.BLOCK_INFO, BlockInfoModalProps>
  | GenericModalInfo<ModalType.PASSWORD_MODIFY, PasswordModifyModalProps>
  | GenericModalInfo<ModalType.RESEARCHER_LIST, ResearcherListModalProps>
  | GenericModalInfo<ModalType.HELP_CENTER, HelpModalProps>
  | GenericModalInfo<ModalType.UPDATE_ONBOARDING, UpdateOnboardingModalprops>
  | GenericModalInfo<
      ModalType.DOCUMENT_BOX_MEMBER_MANAGE,
      DocumentBoxMemberManageModalProps
    >
  | GenericModalInfo<
      ModalType.CREATE_SHARE_LINK,
      CreateDocumentShareLinkModalProps
    >
  | GenericModalInfo<ModalType.NDA_TEMPLATE, NDATemplateSelectModalProps>
  | GenericModalInfo<
      ModalType.CREATE_SHARE_LINK_COMPLETE,
      CreateDocumentShareLinkCompleteModalProps
    >
  | GenericModalInfo<ModalType.MOVE_DOCUMENT, MoveDocumentModalProps>
  | GenericModalInfo<ModalType.EDIT_NDA_TEMPLATE, EditNDATemplateModalProps>
  | GenericModalInfo<ModalType.FREE_PLAN_FORM, FreePlanFormModalProps>
  | GenericModalInfo<ModalType.DATA_ROOM_PRICE, DataRoomPriceModalProps>
  | GenericModalInfo<ModalType.SELECT_DOCUMENT, {}>
  | GenericModalInfo<ModalType.DATA_ROOM_GUIDE, DataRoomGuideModalProps>
  | GenericModalInfo<ModalType.DOWNLOAD_FAIL_FILE, DownloadFailFileModalProps>
  | GenericModalInfo<ModalType.NDA_SIGN_LIST, NdaSignListModalProps>
  | GenericModalInfo<
      ModalType.INVITE_NEW_WORKSPACE,
      InviteNewWorkspaceModalProps
    >;

export type ModalState = {
  readonly status: ModalStateStatus.INIT;
  readonly openedModalList: ModalInfo[];
};

export type ModalAction =
  | ({
      readonly kind: ModalActionKind.TRY_OPEN;
    } & ModalInfo)
  | {
      readonly kind: ModalActionKind.TRY_CLOSE;
    }
  | {
      readonly kind: ModalActionKind.TRY_CLOSE_ALL;
    };

const smid = "MODAL_STATE_MACHINE3";

export type ModalStateMachineType = StateMachine3<
  ModalStateStatus,
  ModalState,
  ModalActionKind,
  ModalAction,
  ModalError
>;

export const modalStateMachine: ModalStateMachineType = new StateMachine3<
  ModalStateStatus,
  ModalState,
  ModalActionKind,
  ModalAction,
  ModalError
>(smid, { status: ModalStateStatus.INIT, openedModalList: [] }, [
  transition(
    ModalStateStatus.INIT,
    ModalStateStatus.INIT,
    ModalActionKind.TRY_OPEN
  ),
  transition(
    ModalStateStatus.INIT,
    ModalStateStatus.INIT,
    ModalActionKind.TRY_CLOSE
  ),
  transition(
    ModalStateStatus.INIT,
    ModalStateStatus.INIT,
    ModalActionKind.TRY_CLOSE_ALL
  ),
]);

export type DispatchModalAction = Dispatch<
  StateMachineAction<
    ModalStateStatus,
    ModalState,
    ModalActionKind,
    ModalAction,
    ModalError
  >
>;
export default mkReducer<
  ModalStateStatus,
  ModalState,
  ModalActionKind,
  ModalAction,
  ModalError
>(modalStateMachine);
export const doModalAction = (
  dispatch: DispatchModalAction,
  nextAction: ModalAction,
  onResolve: () => void = () => {},
  onReject: (err: ModalError | InternalError) => void = () => {}
) => {
  dispatch(modalStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doModalActionAsync = (
  dispatch: DispatchModalAction,
  nextAction: ModalAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(modalStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetModal = (dispatch: DispatchModalAction) => {
  dispatch(modalStateMachine.newResetAction());
};
