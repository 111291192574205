import { useGetWorkspaceInvitationQuery } from "@react-query/workspace/useGetWorkspaceInvitationQuery";
import { useQueryString } from "@utils/hooks/pure/useQueryString";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";
import {
  doWorkspaceAction,
  WorkspaceActionKind,
} from "src/redux/store/reducers/workspace";

export const useCheckWorkspaceInvitation = () => {
  const dispatch = useDispatch();
  const queryString = useQueryString();
  const invitationId = queryString.parse(`invitationId`);

  const { workspaceInvitation } = useSelector(
    (state: RootState) => state.workspace
  ).state;

  const workspaceInvitationQuery = useGetWorkspaceInvitationQuery({
    invitationId,
  });

  React.useEffect(() => {
    if (!workspaceInvitationQuery.isSuccess) return;

    /** 초대메일 타고 들어오지 않았는데 초대 기록 저장중이면 비워주기 */
    if (!invitationId && workspaceInvitation) {
      doWorkspaceAction(dispatch, {
        kind: WorkspaceActionKind.TRY_UPDATE_WORKSPACE_INVITATION,
        workspaceInvitation: undefined,
      });

      return;
    }

    if (workspaceInvitationQuery.data) {
      doWorkspaceAction(dispatch, {
        kind: WorkspaceActionKind.TRY_UPDATE_WORKSPACE_INVITATION,
        workspaceInvitation: workspaceInvitationQuery.data,
      });
    }
  }, [workspaceInvitationQuery.isSuccess]);
};
