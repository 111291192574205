import { getWorkspaceInvitation } from "@goono-react-commons/services/workspace";
import { WorkspaceQueryKey } from "@react-query/key/workspace";
import { useQuery } from "react-query";

type Props = {
  invitationId: string | undefined;
};

export const useGetWorkspaceInvitationQuery = (props: Props) => {
  return useQuery([WorkspaceQueryKey.getWorkspaceInvitation], async () => {
    if (!props.invitationId) return undefined;
    return await getWorkspaceInvitation(props.invitationId);
  });
};
