import { ScreenURL } from "./route_list";
import { lazy } from "react";
import { RouteProps } from "react-router-dom";
import SigninScreen from "src/screens/auth/SignInScreen";
import WorkspaceCheckoutScreen from "src/screens/workspace/WorkspaceCheckoutScreen";

const routes_landing: Array<RouteProps> = [
  {
    path: ScreenURL.SIGN_IN,
    exact: true,
    component: SigninScreen,
  },
  {
    path: ScreenURL.SIGN_UP,
    exact: true,
    component: lazy(() => import("../screens/auth/SignUpScreen/index")),
  },
  {
    path: ScreenURL.REGISTER,
    exact: true,
    component: lazy(() => import("../screens/auth/RegisterSNSScreen/index")),
  },
  {
    path: ScreenURL.REGISTER_EMAIL,
    exact: true,
    component: lazy(() => import("../screens/auth/RegisterEmailScreen/index")),
  },
  {
    path: ScreenURL.EMAIL_VERIFEID,
    exact: true,
    component: lazy(
      () => import("../screens/auth/EmailVerificationSuccessScreen")
    ),
  },
  {
    path: ScreenURL.UPDATE_PW,
    exact: true,
    component: lazy(() => import("../screens/auth/UpdatePasswordScreen/index")),
  },
  {
    path: ScreenURL.AUTH_PW,
    exact: true,
    component: lazy(() => import("../screens/auth/AuthPasswordScreen/index")),
  },
  {
    path: ScreenURL.LANDING_CALLBACK,
    exact: true,
    component: lazy(() => import("../screens/callback/LandingCallbackScreen")),
  },
];

const routes_common: Array<RouteProps> = [
  {
    path: ScreenURL.CERTIFICATION,
    exact: true,
    component: lazy(
      () => import("../screens/research-note/CertificationScreen")
    ),
  },
  {
    path: ScreenURL.TERM,
    exact: true,
    component: lazy(() => import("../screens/common/TermsScreen")),
  },
  {
    path: ScreenURL.TERM_INFO,
    exact: true,
    component: lazy(() => import("../screens/common/TermsScreen")),
  },
  {
    path: ScreenURL.SERVICE_CALLBACK,
    exact: true,
    component: lazy(() => import("../screens/callback/ServiceCallbackScreen")),
  },
  {
    path: ScreenURL.DATA_ROOM_SHARED_DOCUMENT_PREVIEW,
    exact: true,
    component: lazy(
      () => import("../screens/data-room/SharedDocumentPreviewScreen/index")
    ),
  },
  {
    path: ScreenURL.DATAROOM_CONTRACTED_NDA_PREVIEW,
    exact: true,
    component: lazy(
      () => import("../screens/data-room/ContractedNDAPreviewScreen/index")
    ),
  },
];

const routes_service: Array<RouteProps> = [
  {
    path: ScreenURL.WORKSPACE_CHECKOUT,
    exact: true,
    component: WorkspaceCheckoutScreen,
  },
  {
    path: ScreenURL.WORKSPACE_IP_BLOCKING,
    exact: true,
    component: lazy(
      () => import("../screens/workspace/WorkspaceIPBlockingScreen")
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_DASHBOARD,
    exact: true,
    component: lazy(
      () => import("../screens/research-note/ResearchNoteDashboardScreen/index")
    ),
  },
  {
    path: ScreenURL.WORKSPACE_SETTING,
    exact: true,
    component: lazy(
      () => import("../screens/workspace/WorkspaceSettingScreen/index")
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_PRICE_GUIDE,
    exact: true,
    component: lazy(
      () => import("../screens/service-other/PriceInfomationScreen/index")
    ),
  },
  {
    path: ScreenURL.CUSTOM_PURCHASE,
    exact: true,
    component: lazy(
      () => import("../screens/payment/CustomPurchaseScreen/index")
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_HELP,
    exact: true,
    component: lazy(() => import("../screens/service-other/ServiceHelpScreen")),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_PROJECT_LIST,
    exact: true,
    component: lazy(
      () => import("../screens/research-note/ProjectListScreen/index")
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_GITHUB_LINKED_HISTORY,
    exact: true,
    component: lazy(
      () => import("../screens/research-note/GithubLinkedHistoryScreen")
    ),
  },
  {
    path: ScreenURL.MYPAGE,
    exact: true,
    component: lazy(() => import("../screens/user/MyPageScreen/index")),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_AUTHS,
    exact: true,
    component: lazy(
      () => import("../screens/research-note/AuthProgressScreen")
    ),
  },
  {
    path: ScreenURL.PURCHASE,
    exact: true,
    component: lazy(() => import("../screens/payment/PurchaseScreen")),
  },
  {
    path: ScreenURL.PAYMENT_RESULT,
    exact: true,
    component: lazy(() => import("../screens/payment/PaymentResultScreen")),
  },
  {
    path: ScreenURL.PAYMENT_VBANK,
    exact: true,
    component: lazy(() => import("../screens/payment/PaymentVbankScreen")),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_SEARCH_RESULT,
    exact: true,
    component: lazy(
      () =>
        import("../screens/research-note/ResearchNoteGlobalSearchScreen/index")
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_CONTACT,
    exact: true,
    component: lazy(
      () => import("../screens/service-other/ServiceContactScreen")
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_UPLOAD,
    exact: true,
    component: lazy(
      () => import("../screens/research-note/ResearchFileUploadScreen/index")
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_UPLOAD_TYPE,
    exact: true,
    component: lazy(
      () => import("../screens/research-note/ResearchFileUploadScreen/index")
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_PROJECT_PREVIEW,
    exact: true,
    component: lazy(
      () => import("../screens/research-note/FilePreviewScreen/index")
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_SAMPLE_FILE_PREVIEW,
    exact: true,
    component: lazy(
      () => import("../screens/research-note/FilePreviewScreen/index")
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_ADMIN_DASHBOARD,
    exact: true,
    component: lazy(
      () =>
        import(
          "../screens/research-note/ResearchNoteAdminDashboardScreen/index"
        )
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_ADMIN_DASHBOARD_PROJECT,
    exact: true,
    component: lazy(
      () => import("../screens/research-note/ProjectInsideScreen/index")
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_ADMIN_DASHBOARD_PROJECT_FOLDER_INSIDE,
    exact: true,
    component: lazy(
      () => import("../screens/research-note/ProjectInsideScreen/index")
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_ADMIN_DASHBOARD_PREVIEW,
    exact: true,
    component: lazy(
      () => import("../screens/research-note/FilePreviewScreen/index")
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_CREATE_PROJECT,
    exact: true,
    component: lazy(
      () => import("../screens/research-note/ProjectWriteScreen")
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_EDIT_PROJECT,
    exact: true,
    component: lazy(
      () => import("../screens/research-note/ProjectWriteScreen")
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_PROJECT_INSIDE,
    exact: true,
    component: lazy(
      () => import("../screens/research-note/ProjectInsideScreen/index")
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_PROJECT_FOLDER_INSIDE,
    exact: true,
    component: lazy(
      () => import("../screens/research-note/ProjectInsideScreen/index")
    ),
  },
  {
    path: ScreenURL.RESEARCH_NOTE_GITHUB_LINK,
    exact: true,
    component: lazy(() => import("../screens/research-note/GithubLinkScreen")),
  },
  {
    path: ScreenURL.WORKSPACE_CREATE,
    exact: true,
    component: lazy(
      () => import("../screens/workspace/WorkspaceCreateScreen/index")
    ),
  },
  {
    path: ScreenURL.WORKSPACE_EMPTY,
    exact: true,
    component: lazy(
      () => import("../screens/workspace/WorkspaceEmptyScreen/index")
    ),
  },
  {
    path: ScreenURL.WORKSPACE_SETTING,
    exact: true,
    component: lazy(
      () => import("../screens/workspace/WorkspaceSettingScreen/index")
    ),
  },

  {
    path: ScreenURL.DATAROOM_LIST,
    exact: true,
    component: lazy(
      () => import("../screens/data-room/DocumentBoxListScreen/index")
    ),
  },

  {
    path: ScreenURL.DATAROOM_SEARCH_RESULT,
    exact: true,
    component: lazy(
      () => import("../screens/data-room/DataRoomGlobalSearchScreen/index")
    ),
  },
  {
    path: ScreenURL.DATAROOM_DOCUMENT_BOX_INSIDE,
    exact: true,
    component: lazy(
      () => import("../screens/data-room/DocumentBoxInsideScreen")
    ),
  },
  {
    path: ScreenURL.DATAROOM_DOCUMENT_BOX_FOLDER_INSIDE,
    exact: true,
    component: lazy(
      () => import("../screens/data-room/DocumentBoxInsideScreen")
    ),
  },
  {
    path: ScreenURL.DATAROOM_DOCUMENT_INSIDE,
    exact: true,
    component: lazy(() => import("../screens/data-room/DocumentInsideScreen")),
  },
  {
    path: ScreenURL.DATAROOM_DOCUMENT_BOX_CREATE,
    exact: true,
    component: lazy(
      () => import("../screens/data-room/DocumentBoxWriteScreen")
    ),
  },
  {
    path: ScreenURL.DATAROOM_DOCUMENT_BOX_EDIT,
    exact: true,
    component: lazy(
      () => import("../screens/data-room/DocumentBoxWriteScreen")
    ),
  },
  {
    path: ScreenURL.DATAROOM_DOCUMENT_BOX_INFO,
    exact: true,
    component: lazy(
      () => import("../screens/data-room/DocumentBoxWriteScreen")
    ),
  },
  {
    path: ScreenURL.DATAROOM_DOCUMENT_SHARE_LINK_EMAIL_SEND,
    exact: true,
    component: lazy(() => import("../screens/data-room/EmailSendScreen")),
  },
  {
    path: ScreenURL.DATAROOM_NDA_MANAGEMENT,
    exact: true,
    component: lazy(() => import("../screens/data-room/NDAManagementScreen")),
  },
  {
    path: ScreenURL.DATAROOM_NDA_INSIDE,
    exact: true,
    component: lazy(
      () => import("../screens/data-room/NDATemplateInsideScreen")
    ),
  },
  {
    path: ScreenURL.DATAROOM_NDA_PREVIEW,
    exact: true,
    component: lazy(
      () => import("../screens/data-room/NDATemplatePreviewScreen")
    ),
  },
  {
    path: ScreenURL.DATAROOM_NDA_SIGN,
    exact: true,
    component: lazy(() => import("../screens/data-room/NDACustomScreen")),
  },
  {
    path: ScreenURL.DATAROOM_DOCUMENT_PREVIEW,
    exact: true,
    component: lazy(() => import("../screens/data-room/DocumentPreviewScreen")),
  },
  {
    path: ScreenURL.DATAROOM_DASHBOARD,
    exact: true,
    component: lazy(
      () => import("../screens/data-room/DataRoomDashboardScreen")
    ),
  },
  {
    path: ScreenURL.DATAROOM_SETTING,
    exact: true,
    component: lazy(
      () => import("../screens/data-room/DataRoomSettingScreen/index")
    ),
  },
];

export { routes_landing, routes_service, routes_common };
