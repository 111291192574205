import { createStyles, makeStyles } from "@material-ui/core";
import { ErrorRounded } from "@material-ui/icons";
import { service } from "@theme";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import TextHighlight from "@ui/goono/components/typo/TextHighlight";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";

export default function WorkspaceInvitationBanner() {
  const classes = useStyles();

  const { workspaceInvitation } = useSelector(
    (state: RootState) => state.workspace
  ).state;

  if (!workspaceInvitation) return <></>;

  return (
    <div className={classes.root}>
      <ErrorRounded className={classes.alert_icon} />
      <GoonoTypography keepAll>
        <TextHighlight
          value={`${workspaceInvitation.inviter_name} 님이${" "}${
            workspaceInvitation.WorkspaceName
          } 워크스페이스에 초대했어요. 회원가입 후 워크스페이스에 자동 가입됩니다.`}
          highlightStyleMap={
            new Map([
              [workspaceInvitation.inviter_name!, { type: "b3_b" }],
              [
                workspaceInvitation.WorkspaceName!,
                { type: "b3_b", textcolor: "primary" },
              ],
            ])
          }
        />
      </GoonoTypography>
    </div>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 24,
      width: `100%`,
      background: service.primary_50,
      border: `1px solid ${service.primary_100}`,
      borderRadius: 8,
      padding: 20,
      display: `flex`,
      gap: 4,
    },

    alert_icon: {
      color: service.primary_900,
      marginTop: 4,
    },
  })
);
